import {
    AnswersComparison,
    BlocksWithUniqueRules,
    ComparisonBlockType,
    ComparisonCloseColor,
    ComparisonType,
    MarkdownTypes,
    OffsetsRecord
} from "../../models/articleCompare"

const enum paragraphMdWords {
    Added = "\n\n;;.compare-added",
    Removed = "\n\n;;.compare-removed",
    Close = ";;",
    Paragraph = "\n\n",
    NextLine = " \n"
}

const enum quoteMdWords {
    Added = "\n\n>;;.compare-added",
    Removed = "\n\n>;;.compare-removed",
    Close = ";;",
    NextLine = "\n> "
}

const getOffsets = (mdType: MarkdownTypes): OffsetsRecord => {
    switch (mdType) {
        case MarkdownTypes.Header:
            return { open: -50, close: 14 }
        default:
            return { open: 0, close: 0 }
    }
}

const getBlockMdWords = (
    comparisonBlockType: ComparisonBlockType | ComparisonType,
    offset: OffsetsRecord,
    color?: ComparisonCloseColor
): string => {
    switch (comparisonBlockType) {
        case ComparisonBlockType.Added:
            return `\n\`\`\`comparison(type:${ComparisonBlockType.Added}, openOffset:${offset.open}, closeOffset:${offset.close})\n\`\`\`\n`
        case ComparisonBlockType.Removed:
            return `\n\`\`\`comparison(type:${ComparisonBlockType.Removed}, openOffset:${offset.open}, closeOffset:${offset.close})\n\`\`\`\n`
        case ComparisonBlockType.Ignored:
            return `\n\`\`\`comparison(type:${ComparisonBlockType.Ignored}, openOffset:${offset.open}, closeOffset:${offset.close})\n\`\`\`\n`
        case ComparisonBlockType.Close:
            return `\n\`\`\`comparison(type:${ComparisonBlockType.Close}, openOffset:${offset.open}, closeOffset:${offset.close}, closeColor:${color})\n\`\`\``
        default:
            return ""
    }
}

const pasteCustomBlock = (comparisonType: ComparisonType, accum: string, content: string, mdType: MarkdownTypes) => {
    const isIgnoredBlock = mdType === MarkdownTypes.Scenario
    const offset = getOffsets(mdType)

    if (isIgnoredBlock) {
        return (
            accum +
            getBlockMdWords(ComparisonBlockType.Ignored, offset) +
            content +
            getBlockMdWords(ComparisonBlockType.Close, offset, ComparisonBlockType.Added)
        )
    }
    const closeColor = comparisonType === ComparisonType.Added ? ComparisonBlockType.Added : ComparisonBlockType.Removed
    return (
        accum +
        getBlockMdWords(comparisonType, offset) +
        content +
        "\n\n" +
        getBlockMdWords(ComparisonBlockType.Close, offset, closeColor)
    )
}

//если дальше будет стрелять множественная вложенность параграфов в блоке текста, то придется делать по аналогии с цитатами
const textPreProcessor = (text: string) => {
    const textWithoutParagraph = text.replace(/\n\n/g, "\n")

    const textWithoutBreakEnd =
        textWithoutParagraph[textWithoutParagraph.length - 1] === "\n"
            ? textWithoutParagraph.slice(0, -1)
            : textWithoutParagraph

    const textWithoutBreakStart = textWithoutBreakEnd[0] === "\n" ? textWithoutBreakEnd.slice(1) : textWithoutBreakEnd

    const isWithMdParagraph = textWithoutBreakStart.match(/^:::.txt-(left|right|center|justify)$/im) !== null
    return { parsedText: textWithoutBreakStart, isWithMdParagraph }
}

//noNextLineAfterCompare - в случае вставки параграфа с выравниванием текста, не требуется после открывающего и закрывающего тэга сравнения вставлять перенос строки
const pasteRules = (openWord: string, content: string, noNextLineAfterCompare = true) => {
    const nextLine = noNextLineAfterCompare ? "" : paragraphMdWords.NextLine
    return openWord + nextLine + content + nextLine + paragraphMdWords.Close
}

//Примеры как выглядят цитаты
//"> :::.txt-center\n> Волк\n> :::\n\n"
//"\n\n>;;.compare-removed:::.txt-center\n>" + text + "\n" + ">:::" + ;;
const pasteQuoteRule = (accum: string, openWord: string, content: string) => {
    const splittedQuote = content.split("> \n")
    const processedQuote = splittedQuote.reduce((acc, substring, _, array) => {
        const str = substring.slice(2)
        const isWithMdParagraph = str.match(/:::.txt-(left|right|center|justify)/im) !== null
        if (isWithMdParagraph) {
            const result = str.replace(/:::.txt-(left|right|center|justify)/g, substring => {
                return openWord + substring
            })
            const result2 = result.replace(/:::(?!\.)/g, () => {
                return ":::" + quoteMdWords.Close
            })

            return acc + result2
        }
        // случай когда цитата без выравнивания по ширине
        if (array.length === 1 && !isWithMdParagraph) {
            return acc + openWord + quoteMdWords.NextLine + str + quoteMdWords.NextLine + quoteMdWords.Close
        }
        return acc + openWord + quoteMdWords.NextLine + str + "> " + quoteMdWords.Close
    }, "")

    return accum + processedQuote
}

const compareArticleProcessor = (answers: AnswersComparison[]): string => {
    //to-do пока работает только с первым вариантом ответа (текущей статьей), по всем вариантам ответа еще нужно обсудить и вообще должны ли они сравниваться

    return answers[0].Text.reduce((accum, textComparison) => {
        const { parsedText, isWithMdParagraph } = textPreProcessor(textComparison.Comparison.Value)
        const comparison = textComparison.Comparison

        if (!(textComparison.MarkdownType in BlocksWithUniqueRules) && textComparison.MarkdownType in MarkdownTypes) {
            return pasteCustomBlock(comparison.ComparisonType, accum, parsedText, textComparison.MarkdownType)
        }

        switch (comparison.ComparisonType) {
            case ComparisonType.Unmodified:
                return accum + paragraphMdWords.Paragraph + comparison.Value
            case ComparisonType.Added:
                if (textComparison.MarkdownType === MarkdownTypes.Quote) {
                    return pasteQuoteRule(accum, quoteMdWords.Added, parsedText)
                }
                return pasteRules(accum + paragraphMdWords.Added, parsedText, isWithMdParagraph)

            case ComparisonType.Removed:
                if (textComparison.MarkdownType === MarkdownTypes.Quote) {
                    return pasteQuoteRule(accum, quoteMdWords.Removed, parsedText)
                }
                return pasteRules(accum + paragraphMdWords.Removed, parsedText, isWithMdParagraph)

            default:
                return accum + paragraphMdWords.Paragraph + comparison.Value
        }
    }, "")
}

export default compareArticleProcessor
