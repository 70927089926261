import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { push } from "connected-react-router"
import { Form, Formik, FormikProps } from "formik"
import { MouseEventHandler, useRef } from "react"
import { Form as BsForm } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import CheckBoxValidatableInput from "../../../../components/CheckBoxValidatableInput/CheckBoxValidatableInput"
import { DatePickerInput } from "../../../../components/DatePickerInput"
import DocLink from "../../../../components/DocLink/DocLink"
import FileUploader from "../../../../components/FileUploader/FileUploader"
import LoadingButton from "../../../../components/LoadingButton/LoadingButton"
import RadioButtonValidatableInput from "../../../../components/RadioButtonValidatableInput/RadioButtonValidatableInput"
import TooltipTrigger from "../../../../components/TooltipTrigger/TooltipTrigger"
import DebouncedValidatableInput from "../../../../components/ValidatableInput/DebouncedValidatableInput"
import {
    MassMailingFormFields,
    MassMailingFormValues,
    MassMailingFormat,
    MassMailingMethod,
    SendingJob
} from "../../../../models/massMailing"
import { ModifyMassMailing } from "../../../../permissions"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { nameof2 } from "../../../../utility/common/nameof"
import { Time } from "../../../../utility/common/time"
import usePermissionsCheck from "../../../../utility/common/usePermissionsCheck"
import { testId } from "../../../../utility/tests/testId"
import KnowledgeBaseInWorkplace from "../../../KnowledgeBaseInWorkplace/KnowledgeBaseInWorkplace"
import { ChannelSelect } from "../../../../components/ChannelSelect/ChannelSelect"
import styles from "./MassMailingForm.module.scss"
import { useKBSidebarOpenerInMassMailing } from "./hooks/useKnowledgeBaseSidebarOpener"
import { useMassMailingFormSubmitter } from "./hooks/useMassMailingFormSubmitter"
import { getMassMailingFormSchema } from "./schemas/MassMailingForm.schema"
import { truncate } from "lodash"

const tNamespace = "massMailing:"
const tFormNamespace = `${tNamespace}form.`
const DOC_LINK_PATH = "bulkmail"

export interface MassMailingFormProps {
    sendingJob?: SendingJob
}

export const MassMailingForm = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const formRef = useRef<FormikProps<MassMailingFormValues>>(null)
    const openSidebar = useKBSidebarOpenerInMassMailing(formRef)
    const { handleSubmit, isLoading } = useMassMailingFormSubmitter()
    const isModifyAllowed = usePermissionsCheck([ModifyMassMailing])

    if (!isModifyAllowed && projectId) {
        const resetPath = `/project/${projectId}/mass-mailing`
        dispatch(push(resetPath))
    }

    const massMailingFormSchema = getMassMailingFormSchema(t)
    const selectPrevent: MouseEventHandler<HTMLSelectElement> = e => e.preventDefault()

    return (
        <>
            <Formik<MassMailingFormValues>
                initialValues={massMailingFormSchema.default()}
                validationSchema={massMailingFormSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {formikProps => {
                    const articleError = formikProps.errors[MassMailingFormFields.article]
                    const { scheduleTime, format, isScheduled, method, article } = formikProps.values

                    return (
                        <Form className={styles.form} role="form" onSubmit={formikProps.handleSubmit}>
                            <h1 className={styles.form__title}>{t(`${tNamespace}title`)}</h1>
                            <DebouncedValidatableInput
                                id={MassMailingFormFields.title}
                                name={MassMailingFormFields.title}
                                label={t(`${tFormNamespace}${MassMailingFormFields.title}`)}
                                placeholder={t(`${tFormNamespace}${MassMailingFormFields.title}-placeholder`)}
                                data-testid={`${testId.massMailingFormInput}-${MassMailingFormFields.title}`}
                            />
                            <RadioButtonValidatableInput
                                id={nameof2(MassMailingFormFields.format, MassMailingFormat.channel)}
                                name={MassMailingFormFields.format}
                                label={t(`${tFormNamespace}${MassMailingFormat.channel}`)}
                                value={MassMailingFormat.channel}
                                data-testid={`${testId.massMailingFormInput}-${nameof2(
                                    MassMailingFormFields.format,
                                    MassMailingFormat.channel
                                )}`}
                            />
                            <RadioButtonValidatableInput
                                id={nameof2(MassMailingFormFields.format, MassMailingFormat.omni)}
                                name={MassMailingFormFields.format}
                                label={t(`${tFormNamespace}${MassMailingFormat.omni}`)}
                                value={MassMailingFormat.omni}
                                data-testid={`${testId.massMailingFormInput}-${nameof2(
                                    MassMailingFormFields.format,
                                    MassMailingFormat.omni
                                )}`}
                            />
                            <ChannelSelect
                                id={MassMailingFormFields.channel}
                                name={MassMailingFormFields.channel}
                                projectId={projectId}
                                className={styles.form__subgroup}
                                isDisabled={format === MassMailingFormat.channel}
                            />
                            <BsForm.Group>
                                <BsForm.Label className={styles.form__attachment}>
                                    {t(`${tFormNamespace}${MassMailingFormFields.attachment}`)}
                                    <TooltipTrigger
                                        id={MassMailingFormFields.attachment}
                                        className={styles.form__tooltip}
                                        content={
                                            <>
                                                {t(`${tFormNamespace}tooltip-file`)}
                                                <br />
                                                {t(`${tFormNamespace}tooltip-data`)}
                                            </>
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            size="lg"
                                            className={styles.form__icon}
                                        />
                                    </TooltipTrigger>
                                </BsForm.Label>
                                <FileUploader name={MassMailingFormFields.attachment} includeRowFile />
                            </BsForm.Group>
                            <DocLink type={DOC_LINK_PATH}>{t(`${tFormNamespace}doc`)}</DocLink>
                            <BsForm.Group>
                                <CheckBoxValidatableInput
                                    id={MassMailingFormFields.isScheduled}
                                    name={MassMailingFormFields.isScheduled}
                                    label={t(`${tFormNamespace}sheduled`)}
                                    className={styles.form__checkbox}
                                    data-testid={`${testId.massMailingFormInput}-${MassMailingFormFields.isScheduled}`}
                                />
                            </BsForm.Group>
                            <BsForm.Group className={styles.form__subgroup}>
                                <DatePickerInput
                                    name={MassMailingFormFields.scheduleTime}
                                    value={scheduleTime ? Time.stampToLocalDate(scheduleTime) : ""}
                                    onChange={(dates: Date[]) => {
                                        formikProps.setFieldValue(
                                            MassMailingFormFields.scheduleTime,
                                            dates[0].getTime(),
                                            true
                                        )
                                    }}
                                    onClear={() => {
                                        formikProps.setFieldValue(MassMailingFormFields.scheduleTime, 0)
                                    }}
                                    showTimeInput
                                    minDate={new Date()}
                                    disabled={!isScheduled}
                                />
                            </BsForm.Group>
                            <RadioButtonValidatableInput
                                id={nameof2(MassMailingFormFields.method, MassMailingMethod.message)}
                                name={MassMailingFormFields.method}
                                label={t(`${tFormNamespace}${MassMailingMethod.message}`)}
                                value={MassMailingMethod.message}
                                data-testid={`${testId.massMailingFormInput}-${nameof2(
                                    MassMailingFormFields.method,
                                    MassMailingMethod.message
                                )}`}
                            />
                            <DebouncedValidatableInput
                                as="textarea"
                                className={styles.form__subgroup}
                                id={MassMailingFormFields.message}
                                name={MassMailingFormFields.message}
                                placeholder={t(`${tFormNamespace}${MassMailingFormFields.message}-placeholder`)}
                                disabled={method !== MassMailingMethod.message}
                                data-testid={`${testId.massMailingFormInput}-${MassMailingFormFields.message}`}
                            />
                            <RadioButtonValidatableInput
                                id={nameof2(MassMailingFormFields.method, MassMailingMethod.article)}
                                name={MassMailingFormFields.method}
                                label={t(`${tFormNamespace}${MassMailingMethod.article}`)}
                                value={MassMailingMethod.article}
                                data-testid={`${testId.massMailingFormInput}-${nameof2(
                                    MassMailingFormFields.method,
                                    MassMailingMethod.article
                                )}`}
                            />
                            <BsForm.Group className={styles.form__subgroup}>
                                <TooltipTrigger
                                    id={`tooltip-${MassMailingFormFields.article}`}
                                    content={article ? article.Title : ""}
                                    condition={!!article && article.Title.length > 45}
                                    placement="top"
                                >
                                    <BsForm.Control
                                        as="select"
                                        className={styles.form__article}
                                        id={MassMailingFormFields.article}
                                        name={MassMailingFormFields.article}
                                        disabled={method !== MassMailingMethod.article}
                                        isInvalid={Boolean(articleError)}
                                        onMouseDown={selectPrevent}
                                        onClick={openSidebar}
                                        value={article ? article.Code : ""}
                                        data-testid={`${testId.massMailingFormInput}-${MassMailingFormFields.article}`}
                                    >
                                        <option value="" hidden>
                                            {t(`${tFormNamespace}select-article`)}
                                        </option>
                                        {article && (
                                            <option value={article.Code}>
                                                {truncate(article.Title, { length: 45, omission: "..." })}
                                            </option>
                                        )}
                                    </BsForm.Control>
                                </TooltipTrigger>
                                <BsForm.Control.Feedback type="invalid">
                                    {articleError && t(articleError)}
                                </BsForm.Control.Feedback>
                            </BsForm.Group>
                            <LoadingButton
                                className={styles.form__btn}
                                type="submit"
                                data-testid={testId.createMassMailing}
                                loading={isLoading}
                            >
                                {t(`${tFormNamespace}submit`)}
                            </LoadingButton>
                        </Form>
                    )
                }}
            </Formik>
            <KnowledgeBaseInWorkplace />
        </>
    )
}
